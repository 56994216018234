import request from '@/utils/request'

// 图片、文件上传
export function uploadAdmin(data) {
  return request({
    url: '/upload/uploadFile',
    method: 'post',
    data
  })
}


// 图片、文件上传  单个
export function uploadAdminOne(data) {
  return request({
    url: '/upload/uploadOneFile',
    method: 'post',
    data
  })
}



// 单个简历上传
export function personAppenAddFile(data) {
  return request({
    url: '/personAppen/addFile',
    method: 'post',
    data
  })
}

// 列表简历上传
export function wordUpload(data) {
  return request({
    url: '/wordUpload/wordUpload',
    method: 'post',
    data
  })
}

//codeType查询表
export function codeType(data) {
  return request({
    url: '/code/getCodeByStatus',
    method: 'get',
    params: data
  })
}

//获取城市
export function getCityAll(data) {
  return request({
    url: '/city/getCityAll',
    method: 'get',
    params: data
  })
}
