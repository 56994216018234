<template>
 <div class="user">
    <div class="line_one" v-if="dataList">
      <div class="topHeaderLine">项目名称:{{dataList.projectName}}</div>
      <div class="topHeaderTip">
        <div><span class="tipLine">运营专员:</span>{{dataList.userName}}</div>
        <div><span class="tipLine">参与会员:</span>{{dataList.participationMember}}</div>
        <div><span class="tipLine">居间人:</span>{{dataList.intermediary}}</div>
        <div><span class="tipLine">项目地点:</span>{{dataList.country}}-{{dataList.province}}-{{dataList.city}}</div>
      </div>
      <div style="margin:10px 50px 0 25px">
        <div style="float:left;font-weight: 600; margin-right:10px">项目简介:</div>
        <textFlow :info="dataList.projectIntro" :hiddenBtn='true' :lineClamp='3' class="text_area"></textFlow>
      </div>
    </div>
    <div class="line_two">
      <div class="buttonArea">
        <div class="add" @click="goAdd()" v-if="!overNow">
          <i class="el-icon-plus"></i>
          添加项目记录
        </div>
        <div class="over" @click="isOverNow()" v-if="!overNow">
          结束项目
        </div>
      </div>
      <el-table 
      :data="getAttentionList" 
      style="width: 95%" 
      class="data_list" 
      v-loading="loading" 
      row-key="id"
      :tree-props="{ children: 'detailsParams', hasChildren: 'hasChildren' }"
      border>
        <el-table-column label="项目阶段" prop="projectPhase" align="center" width="180" />
        <el-table-column label="项目详况" align="center">
          <template v-slot="{ row }">
            {{row.projectDetail}}
            <span class="blue" @click="goFile(row)"> {{row.fileName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="运营专员" prop="userName" align="center" width="180" />
        <el-table-column label="更新时间" prop="updateTime" align="center" width="180" />
        <el-table-column label="操作" align="center" v-if="!overNow">
          <template v-slot="{ row }">
            <div class="maker">
              <div class="edit" @click="goAdd(row)" v-if="row.hasOwnProperty('detailsParams')">添加</div>
              <div class="edit" @click="goEdit(row)" v-if="row.hasOwnProperty('fileUrl')">编辑</div>
              <div class="delete" @click="deleteOne(row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="600px"
      @close="handleCloseMenu"
      center
      :close-on-click-modal="false"
    >
      <div class="tip">
        <div class="tipOne"></div>
        {{ this.dialogTitle }}
      </div>
      <div class="item_content">
        <el-form label-width="100px" ref="form" :rules="rules" :model="formList">
          <el-form-item label="项目阶段:" prop="projectPhase" v-if="!overFlag && !editFlag && !childrenAdd" >
            <el-input v-model="formList.projectPhase" placeholder="请输入项目阶段" class="inputItem" />
          </el-form-item>
          <div v-for="(item,index) in formList.menuObj" :key="index" class="textForm">
            <el-form-item label="项目详况:" :prop="`menuObj[${index}].projectDetail`" :rules="rules.projectDetail">
              <el-input v-model="item.projectDetail" placeholder="请输入项目详况" class="inputItem" :autosize="{ minRows: 4, maxRows: 6}" type="textarea"/>
            </el-form-item>
            <el-form-item label="项目附件:" :prop="`menuObj[${index}].fileName`" :rules="rules.fileName">
              <div @click="getIndex(index)" style="display:flex">
                <el-input v-model="item.fileName" placeholder="xxx.pdf" style="width:200px" />
                <uploadFile 
                  :lookUrl.sync="clientAlias1"
                  :saveUrl.sync="clientAlias1" 
                  :explainFileList.sync="clientAlias"
                  :index="index" 
                  class="updateBtn"
                />
              </div>
            </el-form-item>
            <el-form-item label="运营专员:">
              {{userName}}
            </el-form-item>
            <div class="addForm" @click="addForm" v-if="!overFlag && !editFlag">+</div>
            <div class="cancelForm" @click="cancelForm" v-if="index != 0 && !overFlag">-</div>
          </div>
        </el-form>
        <div class="btn_area">
          <div class="btn" @click="endProject('form')" v-if="overFlag">保存</div>
          <div class="btn" @click="editProject('form')" v-else-if="editFlag">修改</div>
          <div class="btn" @click="addRole('form')" v-else>保存</div> 
          <div class="btn" @click="handleCloseMenu()" style="margin-left:20px">取消</div>
        </div>
      </div>
    </el-dialog>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.current"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>


<script>
import textFlow from '@/components/textflow.vue'
import { pagination } from '@/mixins/pagination'
import uploadFile from '@/components/UploadFile'
import { getProjectStatus, getProjectList, editPhaseDetails, saveProjectStatus, deletePhaseDetails, deleteProjectPhase, saveProjectPhaseDetails } from '@/api/object.js'
const defaultQueryInfo = Object.freeze({
  current: 1,
  size: 10,
})
export default {
  components: { textFlow, uploadFile },
  mixins: [pagination],
  data () {
    return {
        dialogTitle:'',
        dataUserList:[],
        getAttentionList:[{},{}],
        loading:false,
        dialogVisible:false,
        overFlag:false,
        editVisible:false,
        overNow:false,
        editFlag:false,
        childrenAdd:false,
        clientAlias1:'',
        paramsList:{},
        clientAlias:[],
        editList:[],
        indexNum:'',
        queryInfo: { ...defaultQueryInfo },
        dataList:[],
        projectPhase:'',
        formList:{
          menuObj:[{
            projectDetail:'',
            projectPhase:'',
            fileName:''
          }],
        },
        userName:'',
        id:0,
        rules:{
          projectPhase:[{ required: true, message: '请输入项目阶段', trigger: 'blur' }],
          projectDetail:[{ required: true, message: '请输入项目详况', trigger: 'blur' }],
          fileName:[{ required: true, message: '上传附件', trigger: 'change' }]
        }
    }
  },
  watch: {
    clientAlias:{
      handler: function (newVal, oldVal) {
        // console.log(newVal,"new");
        if(newVal){
          this.formList.menuObj[this.indexNum].fileName = newVal[0].name
          this.formList.menuObj[this.indexNum].fileUrl = newVal[0].url
        }else{
          this.formList.menuObj[this.indexNum].fileName = oldVal[0].name
          this.formList.menuObj[this.indexNum].fileUrl = oldVal[0].url
        } 
      },
      deep: true
    }
  },
  mounted(){    
    this.$nextTick(()=>{
      this.id = this.$route.query.id 
      this.queryInfo.id = this.id 
      this.userName = sessionStorage.getItem('userName')
      this.search()
      this.getDetail()
    })
  },
  methods: {
    goFile(row){
      window.open(row.fileUrl)
    },
    deleteOne(row){
      // console.log(row,"id");
      this.$confirm(`确认删除？`, {
        type: "warning",
      }).then(async () => {
        if(row.hasOwnProperty('detailsParams')){
          const { data: res } = await deleteProjectPhase({id:row.id});
          if (res.resultCode===200) {
            this.$messageSuccess("删除成功");
            this.search()
          } else {
            this.$messageError("删除失败");
          }
        }else{
          const { data: res } = await deletePhaseDetails({id:row.id});
          if (res.resultCode===200) {
            this.$messageSuccess("删除成功");
          } else {
            this.$messageError("删除失败");
          }
        }
       
        this.search();
      }).catch(()=>{});
    },
    // 弹窗关闭
    handleCloseMenu(){
      this.dialogVisible = false
      this.editFlag = false
      this.childrenAdd = false
      this.overFlag = false
      this.formList.menuObj = [{
       
      }],
      this.formList.projectPhase = ''
      // this.clientAlias = []
    },
    getIndex(index){
      this.indexNum = index
    },
    async getDetail(){
      const { data: res } = await getProjectList(this.queryInfo)
      if(res.data.list) this.dataList = res.data.list[0]
    },
    endProject(){
      this.formList.projectPhase = '结束项目'
      this.addRole('form')
    },
    async editProject(){
      this.editList = {
        fileName: this.formList.menuObj[0].fileName,
        fileUrl:this.formList.menuObj[0].fileUrl,
        id: this.formList.menuObj[0].id,
        projectDetail:this.formList.menuObj[0].projectDetail,
        userName:this.userName
      }
      const { data:res } = await editPhaseDetails(this.editList)
      if (res.resultCode === 200) {
        this.$message.success('修改成功!')
        this.search()
        this.handleCloseMenu()
      }
    },
    goEdit(row){
      // console.log(row);
      // console.log(row.hasOwnProperty('detailsParams'));
      if(row.hasOwnProperty('detailsParams')){
        this.formList.menuObj[0] = row.detailsParams[0]
      }else{
        this.formList.menuObj[0] = row
      }
      this.editFlag = true
      this.dialogVisible = true
      this.dialogTitle = '编辑项目记录'
      // console.log(this.formList,"!overFlag || !editFlag");
    },
    isOverNow(){
      this.dialogVisible = true
      this.overFlag = true
      this.dialogTitle = '结束项目'
    },
    //添加项目记录
    goAdd(row){
      if(row) {
        this.formList.menuObj[0].projectPhase = row.projectPhase
        this.formList.menuObj[0].projectPhaseId = row.id 
        this.childrenAdd = true
      }
      this.dialogVisible = true
      this.dialogTitle = '添加项目记录'
    },
    addForm(){
      this.formList.menuObj.push(
        {
          projectDetail:'',
          projectPhase:'',
          fileName:''
        },
      )
    },
    cancelForm(){
      this.formList.menuObj.pop({})
    },
    async addRole(formName){
      this.formList.menuObj.map((item)=>{
        if(this.childrenAdd){
          item.projectPhaseId = this.formList.menuObj[0].projectPhaseId
        }else{
          item.projectId = Number(this.id)
        }
        item.projectPhase = this.formList.projectPhase
      })
      // console.log(this.formList.menuObj);
        this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log(this.formList.menuObj,'this.formList.menuObj');
          if(this.childrenAdd){
            const { data:res } = await saveProjectPhaseDetails(this.formList.menuObj)
            if (res.resultCode === 200) {
              this.$message.success('添加成功!')
              this.search()
            }
            else{
              this.$message.success('失败!')
            }
          }
          else{
            const { data:res } = await saveProjectStatus(this.formList.menuObj)
            if (res.resultCode === 200) {
              if(this.overFlag){
                this.$message.success('结束成功!')
              }else{
                this.$message.success('添加成功!')
              }
              this.search()
            }
            else{
              this.$message.success('失败!')
            }
          }
          this.handleCloseMenu()    
        } else {
          this.$message.error('请填写必要的选项!')
          return false;
        }
      });
    },
    async search() {
      this.loading = true
      const { data: res } = await getProjectStatus(this.queryInfo)
      if (res.resultCode === 200) {
        this.getAttentionList = res.data.list
        this.total = res.data.total
        this.loading = false
        this.getAttentionList.map(item=>{
          if(item.projectPhase == '结束项目') this.overNow = true
          if(item.detailsParams.length == 1){
            item.projectDetail = item.detailsParams[0].projectDetail 
            item.fileName = item.detailsParams[0].fileName 
            item.fileUrl = item.detailsParams[0].fileUrl

            item.hasChildren = true
          }
        })
      }
    },
  },
}
</script> 

<style scoped lang="less">
.maker{
  display: flex;
  justify-content: center;
  div{
    font-size: 12px;
    cursor: pointer;
  }
  .delete{
    color: #FF2828;
    margin-left: 12px;
  }
  .edit{
    color: #7C71E1;
    margin-left: 10px;
  }
}
.text_area{
  font-size: 16px;
}
.blue{
  margin-top: 3px;
  color: #3562FF;
  cursor: pointer;
}
.addForm{
  width: 20px;
  height: 20px;
  font-size: 24px;
  color: #ffffff;
  text-align: center;
  line-height: 17px;
  background: #7C71E1;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px auto;
}
.updateBtn{
  margin-left: 10px;
}
/deep/.el-form-item__content{
  display: flex;
}
.btn_area{
  margin: 60px 0 20px 100px;
  width: 100%;
  display: flex;
  .btn{
    color: #fff;
    text-align: center;
    line-height: 40px;
    width: 136px;
    height: 40px;
    background: #7C71E1;
    border-radius: 4px;
    cursor: pointer;
  }
}
.item_content{
  margin: 20px 0px 0px 20px;
 .textForm{
  position: relative;
  .addForm,.cancelForm{
    position: absolute;
    width: 20px;
    height: 20px;
    font-size: 24px;
    color: #ffffff;
    text-align: center;
    line-height: 17px;
    background: #7C71E1;
    border-radius: 50%;
    cursor: pointer;
  }
  .addForm{
    right: 50px;
    top: 70px;
  }
  .cancelForm{
    right: 20px;
    top: 70px;
    font-size: 28px;
    line-height: 15px;
  }
 }
}
.inputItem{
  width: 350px;
}
.tip {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #333333;
  display: flex;
  margin: -30px 0px 30px 25px;
  .tipOne {
    margin: 3px 6px 0px 0px;
    background: #7c71e1;
    border-radius: 11px;
    width: 4px;
    height: 18px;
  }
}
.user{
  width: 100%;
  overflow: hidden;
  height: auto !important;
  margin: 15px 0px 0px 20px;
}
.line_one{
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  .topHeaderLine{
    color: #7563FF;
    font-size: 18px;
    margin: 20px 0 0 25px;
  }
  .topHeaderTip{
    width: 80%;
    margin: 20px 0 0 25px;
    display: flex;
    justify-content: space-between;
    .tipLine{
      font-weight: 600; 
      margin-right: 10px;
    }
  }
} 
.line_two{
  background: #ffffff;
  margin-top: 20px;
  overflow: hidden;
  border-radius: 12px;
  .buttonArea{
      display: flex;
      margin-top: 20px;
      div{
        width: 100px;
        height: 32px;
        text-align: center;
        line-height: 32px;
        font-size: 14px;
        border-radius: 4px;
        margin-left: 20px;
        cursor: pointer;
      }
      .add{
        width: 150px;
        background: #7C71E1;
        color: #ffffff;
      }
      .over{
        border: 1px solid #7C71E1;
        color: #7C71E1;
        background: #ffffff;
      }
    }
  .data_list{
    margin: 20px 0px 20px 20px;
  }
}
.new_page {
  width: 100%;
  text-align: center;
  margin: 30px auto;
}
.tip_two{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 8px;
  div{
    padding: 2px 5px;
    margin-top: 5px;
    font-size: 12px;
    line-height: 17px;
    color: #7067F2;
    background: #7067F21A;
    margin-right: 8px;
  }
}
</style>
