import request from '@/utils/request'

//业务管理--项目管理--查询全部项目
export function getProjectList(data) {
  return request({
    url: '/ProjectController/selectProject',
    method: 'post',
    data
  })
}

//业务管理--项目管理--新增项目
export function addProject(data) {
  return request({
    url: '/ProjectController/saveProject',
    method: 'post',
    data
  })
}

//业务管理--项目管理--删除项目
export function deleteProject(data) {
  return request({
    url: '/ProjectController/deleteProject',
    method: 'get',
    params:data
  })
}

//业务管理--项目管理--1、添加项目记录2、结束项目
export function saveProjectStatus(data) {
  return request({
    url: '/ProjectController/saveProjectPhase',
    method: 'post',
    data
  })
}

//业务管理--项目管理--查询所有会员用户姓名
export function getAllUser(data) {
  return request({
    url: '/ProjectController/selectCustomerName',
    method: 'post',
    data
  })
}

//业务管理--项目管理--显示项目阶段
export function getProjectStatus(data) {
  return request({
    url: '/ProjectController/selectProjectPhase',
    method: 'post',
    data
  })
}

//业务管理--项目管理--修改项目
export function editProject(data) {
  return request({
    url: '/ProjectController/updateProject',
    method: 'post',
    data
  })
}

//业务管理--项目管理--修改项目阶段详情
export function editPhaseDetails(data) {
  return request({
    url: '/ProjectController/updateProjectPhaseDetails',
    method: 'post',
    data
  })
}

//业务管理--项目管理--删除项目阶段详情
export function deletePhaseDetails(data) {
  return request({
    url: '/ProjectController/deleteProjectPhaseDetails',
    method: 'get',
    params:data
  })
}

//业务管理--项目管理--删除项目阶段详情
export function deleteProjectPhase(data) {
  return request({
    url: '/ProjectController/deleteProjectPhase',
    method: 'get',
    params:data
  })
}

//业务管理--项目管理--修改项目阶段详情
export function saveProjectPhaseDetails(data) {
  return request({
    url: '/ProjectController/saveProjectPhaseDetails',
    method: 'post',
    data
  })
}